import React, { useEffect, useRef } from "react"
import Link from "../components/link"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import { globalHistory } from "@reach/router"
import { scrollToRef } from "../utils/dom-utils"
import Button from "react-bootstrap/Button"
import { FaExternalLinkAlt } from "react-icons/all"
import LinkBack from "../components/link-back"

const SonstigesPage = ({ location }) => {
  const beachvolleyballRef = useRef(null)
  const racketserviceRef = useRef(null)

  const navigateToHash = hash => {
    if (!hash) return
    switch (hash) {
      case "#beachvolleyball":
        scrollToRef(beachvolleyballRef)
        break
      case "#racketservice":
        scrollToRef(racketserviceRef)
        break
      default:
        break
    }
  }

  setTimeout(() => navigateToHash(location.hash), 100)

  useEffect(() => {
    // whenever the route changes we change the tab depending on the hash of the url
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hash = globalHistory.location.hash
        navigateToHash(hash)
      }
    })
  })

  return (
    <Layout>
      <Seo title="Sonstiges" />
      <section
        id="banner-sonstiges"
        className="banner d-flex flex-column justify-content-end"
      >
        <Container
          className="text-center text-md-left"
          style={{ maxWidth: "900px" }}
        >
          <p>
            <LinkBack to="/" label={"Homepage"} />
          </p>
          <h1>Sonstiges</h1>
        </Container>
      </section>
      <Container
        className="text-center text-md-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <h2 className="mb-3" ref={beachvolleyballRef}>
            Beachvolleyball
          </h2>
          <p>
            Unsere aktiven Mitglieder des WTHC können Beachvolleyball und
            Beachsoccer ohne zusätzliche Beiträge spielen. Unsere Anlage bietet
            aber auch Nichtmitgliedern, gegen gesonderten Beitrag, attraktive
            Möglichkeiten sich im Sand auszutoben.
          </p>
          <p>
            Die Volleyballplätze können über das Online-Buchungssystem Book&Play
            reserviert werden.
          </p>
          <div className="d-flex justify-content-center justify-content-md-start">
            <Button
              target="_blank"
              href="https://www.bookandplay.de/cgi-bin/WebObjects/BookAndPlay.woa/wa/directAccess?id=HTV-2029"
              className="px-3 d-flex align-items-center justify-content-center"
            >
              Jetzt reservieren
              <FaExternalLinkAlt className="ml-2" />
            </Button>
          </div>
        </section>
        <section>
          <h2 className="mb-3" ref={racketserviceRef}>
            Racket Service
          </h2>
          <p>
            Falls Ihnen einmal die Saite gerissen ist, oder Sie Beratung zu
            Tennisschlägern benötigen, so ist{" "}
            <Link to="/training#trainer">Michael Böttcher</Link> der richtige
            Ansprechpartner.
          </p>
        </section>
      </Container>
    </Layout>
  )
}

export default SonstigesPage
