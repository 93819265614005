import React from "react"
import { FaArrowLeft } from "react-icons/fa"
import Link from "./link"

const LinkBack = ({ label = "Back", to = "/" }) => (
  <Link className="link-back" to={to}>
    <FaArrowLeft className={"icon mr-1 small"} />
    {label}
  </Link>
)

export default LinkBack
